<template>
  <div class="">
    <div class="news_banner">
      <img :src="banner[0].bannerImg" alt="" width="100%" />
    </div>
    <el-row :gutter="20" class="index" type="flex">
      <el-col :span="16" :offset="0">
        <div class="hebing">
          <div class="aa1-3"><!-- 应用场景 -->{{$t('application.Applications')}}</div>
          <el-row>
            <el-col
              :span="8"
              class="bsooo"
              :offset="0"
              v-for="item in list"
              :key="item.id"
            >
              <div class="chufa" @click="navTos(item)">
                <div class="bs0">
                  <img :src="item.icon" alt="" width="100%" />
                  <div class="zzc"></div>
                </div>
                <div class="bs1">{{ item.name }}</div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div style="height: 55px"></div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  metaInfo: {
    // title: "应用",
    meta: [
      {
        // set meta
        name: "keyWords",
        content: "我是keyWords关键字",
      },
      {
        name: "description",
        content: "我是description描述",
      },
    ],
    link: [
      {
        // set link
        rel: "asstes",
        href: "www.baidu.com",
      },
    ],
  },
  // 组件名称
  name: "",
  // 组件参数 接收来自父组件的数据
  props: {},
  // 局部注册的组件
  components: {},
  // 组件状态值
  data() {
    return {
      banner: [
        {
          bannerImg: "",
        },
      ],
      list: "",
    };
  },
  // 计算属性
  computed: {
	  language(){
		  return this.$store.state.Language
	  }
  },
  // 侦听器
  watch: {
	  language(newVal,oldVal){
      window.document.title = this.$t('application.title')
		  this.getList()
		  this.getBanner()
	  }
  },
  // 组件方法
  methods: {
    getBanner() {
	let language = this.$store.state.Language
      this.$http.get("/appBanner/getBannerList?spaceId=10&language="+language).then((res) => {
        this.banner = res.data;
        console.log(this.banner);
      });
    },
    getList() {
	  let language = this.$store.state.Language
      this.$http.get("application/list?language="+ language).then((res) => {
        this.list = res.rows;
      });
    },
    navTos(item) {
      this.$router.push({
        path: "/applicationDetail",
        query: {
          username: item.code,
        },
      });
    },
  },
  // 以下是生命周期钩子   注：没用到的钩子请自行删除
  /**
   * 组件实例创建完成，属性已绑定，但DOM还未生成，$ el属性还不存在
   */
  created() {
    window.document.title = this.$t('application.title')
  },
  /**
   * el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
   * 如果 root 实例挂载了一个文档内元素，当 mounted 被调用时 vm.$ el 也在文档内。
   */
  mounted() {
    this.getBanner();
    this.getList();
  },
};
</script>

<style scoped lang="scss">
.news_banner {
  // background: url(../assets/images/cptou.jpg) no-repeat;
  // background-size: 100% 100%;
  // height: 100%;
  // display: flex;
  // justify-content: flex-start;
  align-items: center;
  text-align: center;
  width: 100%;
  p {
    padding-left: 100px;
  }
}

.index {
  flex-direction: column;
  justify-self: center;
  align-items: center;
}
.hebing {
  text-align: center;
}
.aa1-3 {
  font-size: 30px;
  color: rgb(0, 0, 0);
  margin-top: 50px;
  margin-bottom: 45px;
  font-family: 'Source Han Sans Light','Source Han Sans', 'Source Han Sans Unicode', Geneva, Verdana, sans-serif !important;
}
.aa1-1-3-4 {
  color: rgb(147, 149, 148);
  margin-bottom: 50px;
  margin-top: 8px;
}
.chufa {
  // background-color: aqua;
  margin: 3%;
  margin-bottom: 28px;
}
.bs0 {
  position: relative;
}
.bs0:hover{
	cursor: pointer
}

.zzc {
  background-color: rgba(255, 255, 255, 0);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transition: 0.5s;
}
.bs1 {
  display: flex;
  justify-content: center;
  align-items: center;
  // position: relative;
  // top: -4px;
  // line-height: 50px;
  height: 60px;
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  transition: 0.5s;
}

.chufa:hover .bs1 {
  color: rgb(255, 255, 255);
  background-color: rgb(35, 80, 199);
  transition: 0.5s;
  cursor: pointer;
}

.chufa-1:hover .zzc {
  background-color: rgba(0, 0, 0, 0.5);
  transition: 0.5s;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .bsooo {
    width: 50%;
	height: 361px;
  }
}
@media screen and (max-width: 750px) {
  .bsooo {
    width: 100%;
	height: auto !important;
  }
}
</style>
